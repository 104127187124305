import config from '../config/config'
import { axios } from './index'

let baseUrl = config.baseUrl + '/knowledge/api/v1/post'

export default {
  //获取岗位
  getPostByType(payload) {
    return axios.get(`${baseUrl}/get/post/by-type?type=${payload}`)
  },
  getCategoryListTree() {
    //获取企业下岗位对应的能力和能力对应的题目数量
    return axios.get(`${baseUrl}/get/post/ability/question/count`)
  },
  getAllPosts() {
    // 获取所有岗位
    return axios.get(`${baseUrl}/get/organization/post`)
  },
  //添加岗位
  addPost(payload) {
    return axios.post(`${baseUrl}/add`, payload)
  },
  //修改岗位名称
  editPost(payload) {
    return axios.post(`${baseUrl}/edit`, payload)
  },
  //删除岗位
  deletePost(payload) {
    return axios.get(`${baseUrl}/delete?postId=${payload}`)
  }
}
