<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
    class="yt-dialog yt-dialog-confirm"
  >
    <div class="content">
      <slot />
    </div>
    <div class="footer">
      <el-button @click="visible = false" class="button">取消</el-button>
      <el-button type="primary" class="button" :loading="loading" @click="ok">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: '确认删除'
    }
  },
  data: function() {
    return {
      visible: false,
      loading: false
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
    },
    ok() {
      this.loading = true
      this.$emit('on-ok')
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../theme/variables';

.content {
  text-align: center;
  font-size: @medium;
  color: #000;
  margin-bottom: 44px;
}
</style>
