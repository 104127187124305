<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>专业管理</el-breadcrumb-item>
        <el-breadcrumb-item>专业模型</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-container">
      <div style="display: flex; height: 213px">
        <div class="yt-content" style="width: 360px; margin-right: 10px">
          <p class="title">关联指数</p>
          <div class="statistic-wrapper">
            <div class="icon-background">
              <YTIcon :href="'#icon-lingdaitie3'"></YTIcon>
            </div>
            <ul>
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ postStatistics.postNum }}</span>
                个
              </li>
              <li style="font-size: 12px">专业模型总数</li>
            </ul>
          </div>
          <div class="statistic-wrapper">
            <div class="icon-background">
              <YTIcon :href="'#icon-zhishidian3'"></YTIcon>
            </div>
            <ul>
              <li style="margin-bottom: 5px">
                <span style="font-size: 20px;font-weight: bold">{{ postStatistics.abilityNum }}</span>
                个
              </li>
              <li>关联技能数</li>
            </ul>
          </div>
        </div>
        <div class="yt-content" style="padding-left: 0">
          <p class="title" style="padding-left: 20px">{{ userInfo.organizationName }}专业模型测评次数</p>
          <template v-if="postExamCountList.length > 0">
            <div class="post-exam-list">
              <ul :style="{ height: `${Math.ceil(postExamCountList.length / 2) * 29 - 12}px` }">
                <li v-for="(item, index) in postExamCountList" :style="{ marginBottom: isBottom(index) ? 0 : '12px' }" :key="index" class="item">
                  <p class="textEllipsis" style="max-width: 70%">{{ item.postName }}</p>
                  <span style="color: #666666">{{ item.examCount }}</span>
                </li>
              </ul>
            </div>
          </template>
          <template v-else>
            <div class="empty-exam-list">
              <YTIcon :href="'#icon-zanwushuju'" />
              <p>
                当前该企业暂无专业模型对应的测评次数，
              </p>
              <p>请点击<span @click="toCreatePost">新增专业模型</span>进行创建</p>
            </div>
          </template>
        </div>
      </div>
      <div class="yt-content model-list-wrapper before-down">
        <div class="model-list-head">
          <div>
            <span class="title">当前分类下共{{ total }}个专业模型</span>
            <el-tooltip
              effect="dark"
              content="通过选取不同的技能，配置权重、评价级别组成不同的专业模型，后续包括出卷、测评分析，均依赖该专业模型。"
              placement="top-start"
            >
              <span class="model-button" style="cursor: pointer">
                <YTIcon :href="'#icon-moxingchuangjian'" />
                模型创建
              </span>
            </el-tooltip>
            <el-tooltip effect="dark" content="专业模型是一堆技能的集合，系统、客观的描述了一个专业所需要的技能情况。" placement="top-start">
              <span class="model-button" style="cursor: pointer">
                <YTIcon :href="'#icon-tishi2'" />
                模型简介
              </span>
            </el-tooltip>
          </div>
          <div class="yt-header-toolbar" style="margin-right: 0">
            <el-select v-model="modelType" @change="reSearchPostModel" placeholder="请选择模型类别" class="yt-search-select">
              <el-option v-for="(item, index) in modelTypeEnum" :key="index" :label="item" :value="index"> </el-option>
            </el-select>
            <el-input
              v-model="postKeyword"
              placeholder="通过专业模型名称搜索"
              class="yt-search"
              style="margin-right: 0; width: 200px"
              @keydown.enter.native="reSearchPostModel"
            >
              <i slot="suffix" class="el-input__icon el-icon-sear ch" @click="reSearchPostModel" />
            </el-input>
          </div>
        </div>
        <div class="model-list" v-infinite-scroll="scrollSearchPostModel">
          <ul>
            <li class="create-button" @click="toCreatePost">
              <YTIcon :href="'#icon-xinzeng'" @click="toCreatePost" />
              <p>新增专业模型</p>
            </li>
            <li v-for="model in modelList" :key="model.postId">
              <div style="display: flex; line-height: 20px">
                <span class="type" style="flex-shrink: 0">{{ modelTypeEnum[model.type] }}</span>
                <p style="font-size: 14px; font-weight: bold" class="textEllipsis">{{ model.postName }}</p>
              </div>
              <template v-if="model.postSkillInfoVOS.length > 0">
                <div style="width: 100%;height: 132px;" :ref="`post-skill-${model.postId}`"></div>
              </template>
              <template v-else>
                <div style="height: 132px;display: flex; justify-content: center; align-items: center">
                  <YTIcon :href="'#icon-zanwushuju'" style="font-size: 80px" />
                </div>
              </template>
              <div class="item-line">
                <p>
                  <span>{{ model.mainSkillCount }}</span>
                  个主技能
                </p>
                <p>
                  <span>{{ model.subSkillCount }}</span>
                  个子技能
                </p>
              </div>
              <div class="item-line">
                <p>
                  <template v-if="model.examCount > 0">
                    <span>{{ model.examCount }}</span>
                    次测评
                  </template>
                  <template v-else>
                    暂未测评
                  </template>
                </p>
              </div>
              <div class="item-line deep">创建时间：{{ $formatTime(model.createdTime, 'yyyy-MM-dd') }}</div>
              <div class="item-line deep" style="margin-bottom: 20px">
                <span>模型简介：</span>
                <p class="description two-line-ellipsis">{{ model.postDescription ? model.postDescription : '暂未配置模型简介' }}</p>
              </div>
              <div class="item-line button-list">
                <div class="has-border"><YTIcon :href="'#icon-chakan'" @click="toDetail(model)" /></div>
                <div class="has-border"><YTIcon :href="'#icon-fuzhi'" @click="toCopyPost(model)" /></div>
                <div>
                  <YTIcon
                    :href="'#icon-shanchu'"
                    :style="{ color: model.type === 2 ? '#ECECEC' : '#999', cursor: model.type === 2 ? 'not-allowed' : 'pointer' }"
                    @click="toDeletePost(model)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PostCUDialog ref="postCUDialog" @refresh="reSearchPostModel" />
    <PostCopyDialog ref="postCopyDialog" @refresh="reSearchPostModel" />
    <ConfirmDialog ref="postDeleteDialog" title="删除专业模型" @on-ok="deletePost">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要删除专业模型吗？
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import PostCUDialog from '@components/common/dialog/PostCUDialog'
import PostCopyDialog from '@components/common/dialog/PostCopyDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import { mapGetters } from 'vuex'
import newPostApi from '@api/newPost'
import postApi from '@api/post'
export default {
  name: 'PostModel',
  components: { YTIcon, PostCUDialog, PostCopyDialog, ConfirmDialog },
  data() {
    return {
      postStatistics: {
        abilityNum: 0,
        postNum: 0
      },
      postExamCountList: [], //测评次数列表
      modelList: [], //模型列表
      pageNum: -1,
      pageSize: 10,
      total: 0,
      modelLoading: false, //加载防抖
      modelType: '', //模型类型
      modelTypeEnum: ['学校', '平台', '公开'],
      postKeyword: '', //关键词搜索
      toDeleteId: '', //待删除岗位
      colors: [
        { main: '#167C81', children: ['#B6E4E4', '#A8CFD0', '#86BFCC', '#8DD4B3', '#45C7C2', '#76C5BA', '#78A8A8', '#1797A1'] },
        { main: '#FBD175', children: ['#E4E4B6', '#D2D48D', '#E9D39F', '#F9CA8C', '#DCAE8B', '#DBB274', '#D8BD0A', '#C9862E'] },
        { main: '#D792E3', children: ['#F5BFF8', '#E0C7FC', '#D5B6E4', '#C28DF6', '#BF8DD4', '#A057D8', '#945DA4', '#7C30A1'] },
        { main: '#4CE47A', children: ['#BCE4B6', '#B8EFA2', '#A9D48D', '#7AD174', '#86CC86', '#45C775', '#78BA46', '#17A133'] },
        { main: '#7CAEEF', children: ['#B6D0E4', '#A2DAEF', '#81C4F6', '#50B0E9', '#7CB7CE', '#74A3D1', '#458AC7', '#1757A1'] },
        { main: '#BA5957', children: ['#E8CFCF', '#E4B6B6', '#EFA2A2', '#FD8B8B', '#E46161', '#CC8686', '#D16767', '#A11717'] },
        { main: '#FF7700', children: ['#FFE3C6', '#F9CFAE', '#FFC387', '#FCAA64', '#EBAA71', '#FF9334', '#E47227', '#BF6B0C'] },
        { main: '#37E7F3', children: ['#CFE5E8', '#B6E1E4', '#A2E2EF', '#96DAE8', '#62E4E4', '#67CDD1', '#55B2B5', '#00C4CC'] }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/info'
    }),
    isBottom() {
      return index => {
        let length = this.postExamCountList.length
        return index + 1 === Math.ceil(length / 2) || index + 1 === length
      }
    }
  },
  mounted() {
    this.getPostRelevance()
    this.getPostExamCount()
    window.addEventListener('resize', this.resizeCharts)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeCharts)
  },
  methods: {
    getPostRelevance() {
      //模型和关联技能数量
      newPostApi.getPostRelevance().then(res => {
        this.postStatistics = res.res
      })
    },
    getPostExamCount() {
      //测评次数
      newPostApi.getPostExamCount().then(res => {
        this.postExamCountList = res.res
      })
    },
    searchPostModel() {
      //模型列表,数组用的拼接,刷新数据前需要重置
      let payload = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        postName: this.postKeyword,
        postType: this.modelType
      }
      newPostApi
        .searchPostModel(payload)
        .then(res => {
          this.modelList = this.modelList.concat(res.res.data)
          this.total = res.res.total
          let list = res.res.data
          this.$nextTick(() => {
            for (let i = 0; i < list.length; i++) {
              let item = list[i]
              if (item.postSkillInfoVOS.length > 0) {
                item.postSkillInfoChart = this.initChart(item)
              }
            }
          })
        })
        .finally(() => {
          this.modelLoading = false
        })
    },
    reSearchPostModel() {
      //筛选搜索
      this.pageNum = 0
      this.modelList = []
      this.modelLoading = true
      this.searchPostModel()
    },
    scrollSearchPostModel() {
      //滚动加载模型列表
      if (this.modelLoading) {
        return
      }
      this.modelLoading = true
      this.pageNum += 1
      this.searchPostModel()
    },
    initChart(data) {
      let chartData = data.postSkillInfoVOS.map((item, index) => {
        let mainSkillValue = this.$roundFloat(item.rate, 2)
        let res = {
          name: item.skillName,
          value: mainSkillValue,
          itemStyle: {
            color: this.colors[index < 8 ? index : index - 8].main
          }
        }
        if (item.subSkills.length > 0) {
          res.children = item.subSkills.map((subSkill, subIndex) => {
            return {
              name: subSkill.skillName,
              value: mainSkillValue * subSkill.rate,
              itemStyle: {
                color: this.colors[index < 8 ? index : index - 8].children[subIndex < 8 ? subIndex : subIndex - 8]
              }
            }
          })
        }
        return res
      })
      let option = {
        tooltip: {
          show: true,
          trigger: 'item',
          formatter: function({ name }) {
            return name
          }
        },
        series: {
          type: 'sunburst',
          data: chartData,
          radius: [22, 54],
          itemStyle: {
            borderWidth: 0
          },
          nodeClick: false,
          label: {
            show: false
          }
        }
      }
      let chart = this.$echarts.init(this.$refs[`post-skill-${data.postId}`][0])
      chart.setOption(option)
      return chart
    },
    resizeCharts() {
      for (let i = 0; i < this.modelList.length; i++) {
        if (this.modelList[i].hasOwnProperty('postSkillInfoChart') && this.modelList[i].postSkillInfoChart) {
          this.modelList[i].postSkillInfoChart.resize()
        }
      }
    },
    toCreatePost() {
      //新增岗位模型弹窗
      this.$refs.postCUDialog.open(true)
    },
    toDetail(data) {
      //查看详情
      this.$router.push({ name: 'postModelDetail', params: { postId: data.postId, postType: data.type } })
    },
    toCopyPost(data) {
      //复制岗位模型弹窗
      this.$refs.postCopyDialog.open(data.postId)
    },
    toDeletePost(data) {
      //打开弹窗
      if (data.type === 2) {
        return
      }
      this.toDeleteId = data.postId
      this.$refs.postDeleteDialog.open()
    },
    deletePost() {
      //删除岗位
      postApi.deletePost(this.toDeleteId).then(res => {
        if (res.res === true) {
          this.$message.success('删除专业成功')
          this.reSearchPostModel()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/postModel.less';
.statistic-wrapper {
  .flexStyle(flex, flex-start);
  margin-top: 20px;
  .icon-background {
    .flexStyle();
    width: 56px;
    height: 56px;
    margin-right: 20px;
    background-color: #e6f0ff;
    border-radius: 6px;
  }
  svg {
    font-size: 33px;
    color: #448bff;
  }
}
.post-exam-list {
  height: calc(100% - 40px);
  margin-top: 20px;
  overflow-y: auto;
  ul {
    .flexStyle(flex, flex-start);
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    &:after {
      content: '';
      width: 1px;
      height: 100%;
      background-color: #ececec;
      position: absolute;
      left: 50%;
    }
  }
  .item {
    .flexStyle(flex, space-between);
    width: 50%;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 17px;
    font-size: @small;
  }
}
.empty-exam-list {
  .flexStyle();
  height: 100%;
  flex-direction: column;
  svg {
    font-size: 70px;
  }
  span {
    color: #448bff;
    cursor: pointer;
  }
}
.model-list-wrapper {
  height: calc(100% - 223px);
  margin-top: 10px;
}
.model-list-head {
  .flexStyle(flex, space-between);
  .model-button {
    color: #448bff;
    margin-left: 20px;
    svg {
      font-size: @default;
    }
  }
  .yt-search-select {
    width: 200px;
  }
}
.model-list {
  height: calc(100% - 46px);
  margin-top: 14px;
  margin-right: -12px;
  overflow-y: auto;
  overflow-x: hidden;
  ul {
    margin-right: -16px;
    margin-top: -20px;
    overflow: hidden;
  }
  li {
    float: left;
    width: 212px;
    height: 326px;
    margin-top: 20px;
    margin-right: 20px;
    padding: 10px;
    position: relative;
    background: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 6px;
    .type {
      padding: 0 4px;
      margin-right: 10px;
      color: #448bff;
      background: #e6f0ff;
      border-radius: 2px;
    }
    .item-line {
      .flexStyle(flex, flex-start);
      line-height: 17px;
      margin-bottom: 10px;
      p {
        width: 50%;
        color: #999999;
        span {
          color: #448bff;
          font-weight: bold;
        }
      }
      &.deep {
        color: #666;
        align-items: flex-start;
        p {
          color: #666;
        }
      }
      &.button-list {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        justify-content: space-between;
        font-size: 16px;
        color: #999;
        div {
          width: 33%;
          text-align: center;
        }
        .has-border {
          border-right: 1px solid #d9d9d9;
        }
      }
    }
  }
  .create-button {
    .flexStyle();
    flex-direction: column;
    background: #f5f9ff;
    border: 1px dashed #448bff;
    color: #448bff;
    cursor: pointer;
    svg {
      font-size: 44px;
      margin-bottom: 12px;
    }
  }
}
@media screen and (max-width: 1439px) {
  .model-list {
    li {
      width: calc(25% - 20px);
    }
  }
}
@media screen and (min-width: 1440px) {
  .model-list {
    li {
      width: calc(20% - 20px);
    }
  }
}
</style>
